import {
  getModuleByIdUsingGET as getModule, getModulesUsingGET as getModules,
  getProcessByIdUsingGET as getProcess,
  getProcessCredentialByIdUsingGET as getCredential,
  getProcessCredentialsUsingGET as getCredentials,
  getProcessesUsingGET as getProcesses,
  getSettingByIdUsingGET as getSetting, getSettingsUsingGET as getSettings,
  getTextTemplateByIdUsingGET as getTemplate,
  getTextTemplatesUsingGET as getTemplates, getTranslationsUsingGET as getTranslations,
  getTranslationUsingGET as getTranslation,
  getTriggerCronByIdUsingGET as getCron, getTriggerCronsUsingGET as getCrons,
  getTriggerRestByIdUsingGET as getRest, getTriggerRestsUsingGET as getRests,
  getValidationRuleByIdUsingGET as getValidationRule, getValidationRulesUsingGET as getValidationRules,
  getPluginByIdUsingGET as getPlugin, getPluginsUsingGET as getPlugins,
  getTriggerMessagingsUsingGET as getMessagings, getTriggerMessagingByIdUsingGET as getMessaging,
  getLibrariesUsingGET as getLibraries, getLibraryByIdUsingGET as getLibrary,
  getTriggerEventHandlersUsingGET as getEventHandlers, getTriggerEventHandlerByIdUsingGET as getEventHandler,
  getEntityByIdUsingGET as getEntity, getEntitiesUsingGET as getEntities,
  getEntityResourcesUsingGET as getEntityResources,
  getTriggersByProcessIdUsingGET as getTriggersByProcessId
} from '@/utils/api'
import { getAllStepsData, filterUniqueObjectsByDataIdAndName } from '../../utils/helpers'

const iconHandler = (type) => {
  const icons = {
    PROCESS: 'mdi-console-network',
    TRIGGER_CRON: 'mdi-briefcase-clock-outline',
    TRIGGER_REST: 'mdi-web',
    PROCESS_CREDENTIAL: 'mdi-lock',
    TEXT_TEMPLATE: 'mdi-text-box-outline',
    GLOBAL_TRANSLATION: 'mdi-text',
    VALIDATION_RULE: 'mdi-magnify-scan',
    PLUGIN: 'mdi-puzzle-outline',
    MODULE: 'mdi-briefcase-edit-outline',
    SETTING: 'mdi-cog',
    TRIGGER_MESSAGING: 'mdi-web',
    LIBRARY: 'mdi-library-outline',
    TRIGGER_EVENT_HANDLER: 'mdi-briefcase-outline',
    ENTITY: 'mdi-database-settings-outline'
  }

  return icons[type] || 'mdi-home'
}

// Functions for getting data
export const fetcherDataByType = {
  PROCESS: {
    hasRecursion: false,
    func: getProcesses,
    funcById: getProcess
  },
  TRIGGER_CRON: {
    hasRecursion: false,
    func: getCrons,
    funcById: getCron
  },
  TRIGGER_REST: {
    hasRecursion: false,
    func: getRests,
    funcById: getRest
  },
  PROCESS_CREDENTIAL: {
    hasRecursion: false,
    func: getCredentials,
    funcById: getCredential
  },
  TEXT_TEMPLATE: {
    hasRecursion: false,
    func: getTemplates,
    funcById: getTemplate
  },
  GLOBAL_TRANSLATION: {
    hasRecursion: false,
    func: getTranslations,
    funcById: getTranslation
  },
  VALIDATION_RULE: {
    hasRecursion: true,
    func: getValidationRules,
    funcById: getValidationRule,
    includesKey: 'includes'
  },
  PLUGIN: {
    hasRecursion: false,
    func: getPlugins,
    funcById: getPlugin
  },
  MODULE: {
    hasRecursion: false,
    func: getModules,
    funcById: getModule
  },
  SETTING: {
    hasRecursion: false,
    func: getSettings,
    funcById: getSetting
  },
  TRIGGER_MESSAGING: {
    hasRecursion: false,
    func: getMessagings,
    funcById: getMessaging
  },
  LIBRARY: {
    hasRecursion: false,
    func: getLibraries,
    funcById: getLibrary
  },
  TRIGGER_EVENT_HANDLER: {
    hasRecursion: false,
    func: getEventHandlers,
    funcById: getEventHandler
  },
  ENTITY: {
    hasRecursion: false,
    func: getEntities,
    funcById: getEntity
  }
}

const allResourcesNames = Object.keys(fetcherDataByType)

const resourcesCache = []
const memoizeFoundResources = (resourceToAdd) => {

  return () => {
    if (!resourcesCache.find(
      (resource) => resource.id === resourceToAdd.id && resource.type === resourceToAdd.type
    )) {
      resourcesCache.push(resourceToAdd)
    }

    return resourcesCache
  }
}

const fetchResourceRecursively = async (resourceSearchVal, resourceType, searchKey = 'id') => {
  const fetcherData = fetcherDataByType[resourceType]

  if (fetcherData) {
    const formattedResources = []
    let data

    try {
      if (searchKey === 'id') {
        const res = await fetcherData.funcById({ id: resourceSearchVal })

        data = res?.data?.data || null

      } else {
        const res = await fetcherData.func({ [searchKey]: resourceSearchVal, isSystem: false })

        data = res?.data?.data?.items?.[0] || null
      }

      if (data) {
        formattedResources.push(
          ...[...(
            await getFormattedRelatedResources(data, resourceType)), // Recursively adding related resources too
          {
            type: resourceType,
            data,
            isSelected: false,
            icon: iconHandler(resourceType) || null
          }])

        if (fetcherData.hasRecursion) {
          // If data may contain included related parent/child resources, like validation rules
          const includesKey = fetcherData.includesKey || 'includes'

          if (data[includesKey]?.length > 0) {
            // For validaton rules inclusion is just id; if we need it for different arrays, it may need updates
            await Promise.all(
              data[includesKey].map(
                async (inclusion) => {
                  const fetchedIncludes = await fetchResourceRecursively(inclusion, resourceType, searchKey)

                  if (fetchedIncludes?.length) {

                    formattedResources.push(...fetchedIncludes)
                  }
                }
              ))
          }
        }
      }

      return formattedResources
    } catch (err) {
      console.log(err)

      return null
    }
  }

  return null
}

const fetchProcessTriggers = async (processID) => {
  const fetchedTriggers = []
  const res = await getTriggersByProcessId({ id: processID, request: { page: 1, size: 10000 } })

  const triggerItems = res?.data?.data?.items || null

  if (triggerItems?.length) {
    triggerItems.forEach((item) => {
      const itemType = item.type === 'EXECUTE_PROCESS' ? 'PROCESS' : item.type

      if (!resourcesCache.find(
        (resource) => resource.id === item.id && resource.type === itemType
      )) {
        memoizeFoundResources({ id: item.id, type: itemType })()

        fetchedTriggers.push({
          type: itemType,
          data: item,
          isSelected: false,
          icon: iconHandler(item.type)
        })
      }
    })
  }

  return fetchedTriggers
}

export const getFormattedResourcesForProcessSteps = async (selectedProcess) => {
  const formattedResources = []

  const allProcessSteps = getAllStepsData(selectedProcess.steps.steps,
    (item) =>
      ['EXECUTE_PROCESS', 'PROCESS_SETTING'].includes(item.type)
        || item.properties?.processName
        || item.properties?.textTemplate
        || item.properties?.pluginName
        || item.properties?.credentialName ? item : null
  )

  if (!allProcessSteps?.length) return formattedResources

  await Promise.all(
    allProcessSteps.filter((step) => step !== null).map(async (step) => {
      if (!step) return null
      const {
        processName,
        textTemplate,
        pluginName,
        credentialName,
        processSettingName
      } = step.properties

      if (processName) formattedResources.push(await fetchResourceRecursively(processName, 'PROCESS', 'name'))
      if (textTemplate) formattedResources.push(await fetchResourceRecursively(textTemplate, 'TEXT_TEMPLATE', 'name'))
      if (pluginName) formattedResources.push(await fetchResourceRecursively(pluginName, 'PLUGIN', 'name'))
      if (credentialName) formattedResources.push(await fetchResourceRecursively(credentialName, 'PROCESS_CREDENTIAL', 'name'))
      if (processSettingName) formattedResources.push(await fetchResourceRecursively(processSettingName, 'SETTING', 'name'))

      return formattedResources
    })
  )

  return formattedResources
}

export const getFormattedRelatedResources = async (
  resource,
  resourceType = '',
  addedResources = [],
  root = false
) => {
  // To avoid infinite loops
  if (resourcesCache.find(
    (resourceInCache) =>
      (resource.id === resourceInCache.id
        || resource.name === resourceInCache.name)
      && resourceInCache.type === resourceType
  )) {
    return []
  }
  memoizeFoundResources({ id: resource.id, name: resource.name, type: resourceType })()

  let resourceForRelationsSearch = resource
  const formattedResources = []
  const fetcherData = fetcherDataByType[resourceType]

  const params = resourceType === 'TEXT_TEMPLATE' ? { templateId: resource.id } : { id: resource.id }

  if (fetcherData) {
    const resourceFullRes = await fetcherData.funcById(params)

    if (resourceFullRes?.data?.data) resourceForRelationsSearch = resourceFullRes?.data?.data
  }

  // Pushing everything that may be related
  const {
    processId,
    listenProcessId,
    entityId,
    credentialId,
    inputValidationRuleId,
    outputValidationRuleId,
    validationRules
  } = resourceForRelationsSearch

  if (processId) formattedResources.push(await fetchResourceRecursively(processId, 'PROCESS', 'id'))
  if (listenProcessId) formattedResources.push(await fetchResourceRecursively(listenProcessId, 'PROCESS', 'id'))
  if (entityId) formattedResources.push(await fetchResourceRecursively(entityId, 'ENTITY', 'id'))
  if (credentialId) formattedResources.push(await fetchResourceRecursively(credentialId, 'PROCESS_CREDENTIAL', 'id'))
  if (inputValidationRuleId) formattedResources.push(await fetchResourceRecursively(inputValidationRuleId, 'VALIDATION_RULE', 'id'))
  if (outputValidationRuleId) formattedResources.push(await fetchResourceRecursively(outputValidationRuleId, 'VALIDATION_RULE', 'id'))

  if (validationRules?.length) {
    await Promise.all(validationRules.map(async (validationRule) => {
      const validationRuleId = validationRule.validationRuleId || validationRule.validationRule?.id

      if (validationRuleId) {
        formattedResources.push(await fetchResourceRecursively(validationRuleId, 'VALIDATION_RULE', 'id'))
      }
    }))
  }

  // For a process pushing it's triggers and everything related to it's steps
  if (resourceType && resourceType === 'PROCESS') {
    formattedResources.push(...(await getFormattedResourcesForProcessSteps(resourceForRelationsSearch)))
    formattedResources.push(...(await fetchProcessTriggers(resource.id)))
  }

  // For an entity pushing it's generated resources
  if (resourceType && resourceType === 'ENTITY') {
    const entityResourcesRes = await getEntityResources({ id: resource.id })
    const entityResources = entityResourcesRes?.data?.data || null

    if (entityResources?.length) {
      await Promise.all(entityResources.map(async (entityResource) => {
        formattedResources.push(
          await fetchResourceRecursively(entityResource.resourceId, entityResource.resourceType, 'id')
        )
      }))
    }
  }

  const relatedResourcesFormattedFlatAndUnique = formattedResources
    .flat(Infinity)
    .filter((formattedResource) => formattedResource !== null)
    .filter(filterUniqueObjectsByDataIdAndName)

  // Check what we don't have in the module  
  const relatedResourcesMissing = relatedResourcesFormattedFlatAndUnique/* allSavedResources */
    .filter((step) => {
      if (!step) return false

      return allResourcesNames.includes(step.type)
        && !addedResources.map((x) => x.id).includes(step.data?.id)
    })

  if (root) {
    resourcesCache.length = 0
  }

  return relatedResourcesMissing
}
